<template>
  <form class="create-account-form" @submit.prevent="onSubmit">
    <div class="locale-changer">
      <select v-model="$i18n.locale">
        <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
          {{ $t(lang) }}
        </option>
      </select>
    </div>
    <dx-form :form-data="formData" :disabled="loading">
      <dx-item
        data-field="phone"
        editor-type="dxTextBox"
        :editor-options="{
          stylingMode: 'filled',
          placeholder: 'Телефон',
          mask: '+7(X00)000-00-00',
          maskRules: { X: /[01-9]/ },
        }"
      >
        <dx-required-rule :message="$t('Auth.PhoneNumberRequired')" />
        <dx-label :visible="false" />
      </dx-item>
      <dx-item
        data-field="firstName"
        editor-type="dxTextBox"
        :editor-options="{
          stylingMode: 'filled',
          placeholder: $t('Name'),
          mode: 'text',
        }"
      >
        <dx-required-rule :message="$t('Auth.NameRequired')" />
        <dx-label :visible="false" />
      </dx-item>
      <dx-item
        data-field="lastName"
        editor-type="dxTextBox"
        :editor-options="{
          stylingMode: 'filled',
          placeholder: $t('Surname'),
          mode: 'text',
        }"
      >
        <dx-required-rule :message="$t('Auth.SurnameRequired')" />
        <dx-label :visible="false" />
      </dx-item>
      <dx-item
        data-field="password"
        editor-type="dxTextBox"
        :editor-options="{
          stylingMode: 'filled',
          placeholder: $t('Auth.Password'),
          mode: 'password',
        }"
      >
        <dx-required-rule :message="$t('Auth.PasswordRequired')" />
        <dx-label :visible="false" />
      </dx-item>
      <dx-item
        data-field="confirmedPassword"
        editor-type="dxTextBox"
        :editor-options="{
          stylingMode: 'filled',
          placeholder: $t('Auth.ConfirmPassword'),
          mode: 'password',
        }"
      >
        <dx-required-rule :message="$t('Auth.ConfirmPasswordRequired')" />
        <dx-custom-rule
          :message="$t('Auth.PasswordDontMatch')"
          :validation-callback="confirmPassword"
        />
        <dx-label :visible="false" />
      </dx-item>
      <DxGroupItem>
        <DxSimpleItem
          :editor-options="checkBoxOptions"
          editor-type="dxCheckBox"
        >
        </DxSimpleItem>

        <dx-item>
          <template #default>
            <div class="link">
              <router-link :to="{ name: 'user-agreement' }"
                >Пользовательское соглашение</router-link
              >
            </div>
          </template>
        </dx-item>
      </DxGroupItem>
      <dx-button-item>
        <dx-button-options
          width="100%"
          type="default"
          template="createAccount"
          :use-submit-behavior="true"
          :disabled="disabled"
        >
        </dx-button-options>
      </dx-button-item>
      <template #createAccount>
        <div>
          <span class="dx-button-text">
            <dx-loadIndicator
              v-if="loading"
              width="24px"
              height="24px"
              :visible="true"
            />
            <span v-if="!loading">{{ $t("Auth.CreateAccount") }}</span>
          </span>
        </div>
      </template>
    </dx-form>
    <div class="login-link">
      {{ $t("Auth.HaveAnAccount") }}
      <router-link to="/login">{{ $t("Auth.SignIn") }}</router-link>
    </div>
  </form>
</template>

<script>
import DxForm, {
  DxItem,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
  DxCustomRule,
  DxRequiredRule,
  DxGroupItem,
  DxSimpleItem,
} from "devextreme-vue/form";
import DxLoadIndicator from "devextreme-vue/load-indicator";
import notify from "devextreme/ui/notify";

export default {
  components: {
    DxForm,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxRequiredRule,
    DxCustomRule,
    DxLoadIndicator,
    DxGroupItem,
    DxSimpleItem,
  },
  data() {
    return {
      formData: {},
      loading: false,
      smsConfirm: false,
      langs: ["ru", "kz"],

      disabled: false,

      checkBoxOptions: {
        value: true,
        onValueChanged: (e) => {
          this.disabled = !e.component.option("value");
        },
      },
    };
  },
  created() {
    this.formData.phone = this.$store.getters["auth/getUserName"];
  },
  methods: {
    onSubmit: async function () {
      const { phone, password, firstName, lastName } = this.formData;
      this.loading = true;

      this.$store
        .dispatch("auth/Register", {
          phone: phone,
          password: password,
          firstName: firstName,
          lastName: lastName,
        })
        .then(() => {
          this.loading = false;
          this.smsConfirm = true;
          this.$router.push("/login-form");
        })
        .catch((error) => {
          this.loading = false;
          notify(this.$i18n.t(error.message), "error", 2000);
        });
    },
  },
  confirmPassword(e) {
    return e.value === this.formData.password;
  },
};
</script>

<style lang="scss">
@import "../../themes/generated/variables.base.scss";

.create-account-form {
  // -----------------------------------------------------
  .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
    padding-top: 0;
  }

  .dx-box-flex .dx-box {
    flex-direction: row !important;
  }
  //  ------------------------------------------
  .policy-info {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    font-size: 14px;
    font-style: normal;

    a {
      color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    }
  }

  .login-link {
    margin-top: 10px;
    color: $base-accent;
    font-size: 16px;
    text-align: center;
  }
}
</style>

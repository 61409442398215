<template>
  <form class="reset-password-form" @submit.prevent="onSubmit">
    <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ $t(lang) }}</option>
    </select>
    </div>
    <dx-form :form-data="formData" :disabled="loading">
      <dx-item
        data-field="phone"
        editor-type="dxTextBox"
        :editor-options="{ stylingMode: 'filled', placeholder: 'Phone number', mask: '+7(X00)000-00-00', maskRules: { 'X': /[01-9]/ } }"
      >
        <dx-required-rule :message="$t('Auth.PhoneNumberRequired')" />
        <dx-label :visible="false" />
      </dx-item>
            <dx-item
        data-field="newPassword"
        editor-type="dxTextBox"
        :editor-options="{ stylingMode: 'filled', placeholder: $t('Auth.NewPassword'), mode: 'password' }"
      >
        <dx-required-rule :message="$t('Auth.NewPasswordRequired')" />
        <dx-label :visible="false" />
      </dx-item>
            <dx-item
        data-field="confirmedPassword"
        editor-type="dxTextBox"
        :editor-options="{ stylingMode: 'filled', placeholder: $t('Auth.ConfirmNewPassword'), mode: 'password' }"
      >
        <dx-required-rule :message="$t('Auth.ConfirmPasswordRequired')" />
        <dx-label :visible="false" />
      </dx-item>
      <dx-button-item>
        <dx-button-options
          :element-attr="{ class: 'submit-button' }"
          width="100%"
          type="default"
          template="resetTemplate"
          :use-submit-behavior="true"
        >
        </dx-button-options>
      </dx-button-item>
      <dx-item>
        <template #default>
          <div class="login-link">
            {{$t('Auth.ReturnTo')}} <router-link to="/login">{{$t('Auth.SignIn')}}</router-link>
          </div>
        </template>
      </dx-item>
      <template #resetTemplate>
        <div>
          <span class="dx-button-text">
              <dx-load-indicator v-if="loading" width="24px" height="24px" :visible="true" />
              <span v-if="!loading">{{$t('Auth.ResetPassword')}}</span>
          </span>
        </div>
      </template>
    </dx-form>
  </form>
</template>

<script>
import DxForm, {
  DxItem,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
  DxRequiredRule
} from 'devextreme-vue/form';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import notify from 'devextreme/ui/notify';

//const notificationText = 'We\'ve sent a link to reset your password. Check your inbox.';

export default {
  components: {
    DxForm,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxRequiredRule,
    DxLoadIndicator
  },
  data() {
    return {
        formData: {},
        loading: false,
        langs: ['ru', 'kz']
    }
  },
  created() {
    this.formData.phone = this.$store.getters["auth/getUserName"];
  },
  methods: {
    onSubmit: async function() {
      const { phone, newPassword } = this.formData;
      this.loading = true;

      this.$store.dispatch('auth/ResetPassword', {
      phone: phone,
      newPassword: newPassword
      })
        .then(() => {
          this.loading = false;
          this.$router.push("/login-form");
        })
        .catch(error => {
          this.loading = false;
          notify(error.message, "error", 2000);
        })

      this.loading = false;

    }
  }
}
</script>

<style lang="scss">
@import "../../themes/generated/variables.base.scss";

.reset-password-form {
  .submit-button {
    margin-top: 10px;
  }

  .login-link {
    color: $base-accent;
    font-size: 16px;
    text-align: center;
  }
}
</style>

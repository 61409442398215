var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"create-account-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"locale-changer"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.$i18n.locale),expression:"$i18n.locale"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$i18n, "locale", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.langs),function(lang,i){return _c('option',{key:("Lang" + i),domProps:{"value":lang}},[_vm._v(" "+_vm._s(_vm.$t(lang))+" ")])}),0)]),_c('dx-form',{attrs:{"form-data":_vm.formData,"disabled":_vm.loading},scopedSlots:_vm._u([{key:"createAccount",fn:function(){return [_c('div',[_c('span',{staticClass:"dx-button-text"},[(_vm.loading)?_c('dx-loadIndicator',{attrs:{"width":"24px","height":"24px","visible":true}}):_vm._e(),(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.$t("Auth.CreateAccount")))]):_vm._e()],1)])]},proxy:true}])},[_c('dx-item',{attrs:{"data-field":"phone","editor-type":"dxTextBox","editor-options":{
        stylingMode: 'filled',
        placeholder: 'Телефон',
        mask: '+7(X00)000-00-00',
        maskRules: { X: /[01-9]/ },
      }}},[_c('dx-required-rule',{attrs:{"message":_vm.$t('Auth.PhoneNumberRequired')}}),_c('dx-label',{attrs:{"visible":false}})],1),_c('dx-item',{attrs:{"data-field":"firstName","editor-type":"dxTextBox","editor-options":{
        stylingMode: 'filled',
        placeholder: _vm.$t('Name'),
        mode: 'text',
      }}},[_c('dx-required-rule',{attrs:{"message":_vm.$t('Auth.NameRequired')}}),_c('dx-label',{attrs:{"visible":false}})],1),_c('dx-item',{attrs:{"data-field":"lastName","editor-type":"dxTextBox","editor-options":{
        stylingMode: 'filled',
        placeholder: _vm.$t('Surname'),
        mode: 'text',
      }}},[_c('dx-required-rule',{attrs:{"message":_vm.$t('Auth.SurnameRequired')}}),_c('dx-label',{attrs:{"visible":false}})],1),_c('dx-item',{attrs:{"data-field":"password","editor-type":"dxTextBox","editor-options":{
        stylingMode: 'filled',
        placeholder: _vm.$t('Auth.Password'),
        mode: 'password',
      }}},[_c('dx-required-rule',{attrs:{"message":_vm.$t('Auth.PasswordRequired')}}),_c('dx-label',{attrs:{"visible":false}})],1),_c('dx-item',{attrs:{"data-field":"confirmedPassword","editor-type":"dxTextBox","editor-options":{
        stylingMode: 'filled',
        placeholder: _vm.$t('Auth.ConfirmPassword'),
        mode: 'password',
      }}},[_c('dx-required-rule',{attrs:{"message":_vm.$t('Auth.ConfirmPasswordRequired')}}),_c('dx-custom-rule',{attrs:{"message":_vm.$t('Auth.PasswordDontMatch'),"validation-callback":_vm.confirmPassword}}),_c('dx-label',{attrs:{"visible":false}})],1),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"editor-options":_vm.checkBoxOptions,"editor-type":"dxCheckBox"}}),_c('dx-item',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"link"},[_c('router-link',{attrs:{"to":{ name: 'user-agreement' }}},[_vm._v("Пользовательское соглашение")])],1)]},proxy:true}])})],1),_c('dx-button-item',[_c('dx-button-options',{attrs:{"width":"100%","type":"default","template":"createAccount","use-submit-behavior":true,"disabled":_vm.disabled}})],1)],1),_c('div',{staticClass:"login-link"},[_vm._v(" "+_vm._s(_vm.$t("Auth.HaveAnAccount"))+" "),_c('router-link',{attrs:{"to":"/login"}},[_vm._v(_vm._s(_vm.$t("Auth.SignIn")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <form class="login-form" @submit.prevent="onSubmit">
    <div class="locale-changer">
      <select v-model="$i18n.locale">
        <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
          {{ $t(lang) }}
        </option>
      </select>
    </div>
    <dx-form :form-data="formData" :disabled="loading">
      <dx-item
        data-field="phone"
        editor-type="dxTextBox"
        :editor-options="{
          stylingMode: 'filled',
          placeholder: 'Phone number',
          mask: '+7(X00)000-00-00',
          maskRules: { X: /[01-9]/ },
        }"
      >
        <dx-required-rule :message="$t('Auth.PhoneNumberRequired')" />
        <dx-label :visible="false" />
      </dx-item>
      <dx-item
        data-field="password"
        editor-type="dxTextBox"
        :editor-options="{
          stylingMode: 'filled',
          placeholder: $t('Auth.Password'),
          mode: 'password',
        }"
      >
        <dx-required-rule :message="$t('Auth.PasswordRequired')" />
        <dx-label :visible="false" />
      </dx-item>
      <dx-item
        data-field="rememberMe"
        editor-type="dxCheckBox"
        :editor-options="{
          text: $t('Auth.RememberMe'),
          elementAttr: { class: 'form-text' },
        }"
      >
        <dx-label :visible="false" />
      </dx-item>
      <dx-button-item>
        <dx-button-options
          width="100%"
          type="default"
          template="signInTemplate"
          :use-submit-behavior="true"
        >
        </dx-button-options>
      </dx-button-item>
      <dx-item>
        <template #default>
          <div class="link">
            <router-link
              :to="{ name: 'sms-confirm', params: { id: 'resetpassword' } }"
              >{{ $t("Auth.ForgotPassword") }}</router-link
            >
          </div>
        </template>
      </dx-item>
      <dx-button-item>
        <dx-button-options
          :text="$t('Auth.CreateAccount')"
          width="100%"
          :on-click="onCreateAccountClick"
        />
      </dx-button-item>
      <template #signInTemplate>
        <div>
          <span class="dx-button-text">
            <dx-load-indicator
              v-if="loading"
              width="24px"
              height="24px"
              :visible="true"
            />
            <span v-if="!loading">{{ $t("Auth.SignIn") }}</span>
          </span>
        </div>
      </template>
    </dx-form>
  </form>
</template>

<script>
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
} from "devextreme-vue/form";
import notify from "devextreme/ui/notify";

export default {
  data() {
    return {
      formData: {},
      loading: false,
      langs: ["ru", "kz"],
    };
  },
  methods: {
    onCreateAccountClick() {
      this.$router.push({ name: "sms-confirm", params: { id: "register" } });
    },
    onSubmit: async function () {
      const { phone, password } = this.formData;
      this.loading = true;

      this.$store
        .dispatch("auth/Authorization", {
          phone: phone,
          password: password,
        })
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
          notify(this.$i18n.t(error.message), "error", 2000);
        });
    },
  },
  components: {
    DxLoadIndicator,
    DxForm,
    DxRequiredRule,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
  },
};
</script>

<style lang="scss">
@import "../../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}
.locale-changer select {
  border: none;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  cursor: pointer;
}
</style>

<template>
  <form class="sms-cofirm-form" @submit.prevent="onSubmit">
    <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ $t(lang) }}</option>
    </select>
    </div>
    <dx-form :form-data="formData">
        <dx-item
            data-field="phone"
            editor-type="dxTextBox"
            :editor-options="{ stylingMode: 'filled', placeholder: 'Телефон', mask: '+7(X00)000-00-00', maskRules: { 'X': /[01-9]/ } }"
        >
            <dx-required-rule :message="$t('Auth.PhoneNumberRequired')" />
            <dx-label :visible="false" />
        </dx-item>
        <template v-if="smsConfirm">
            <dx-item
            data-field="smsCode"
            editor-type="dxTextBox"
            :editor-options="{ stylingMode: 'filled', placeholder: $t('Auth.SmsCode'), mask: 'XXXXXX', maskRules: { 'X': /[01-9]/ }  }"
            >
            <dx-required-rule :message="$t('Auth.SmsCodeRequired')" />
            <dx-label :visible="false" />
            </dx-item>
        </template>
        <dx-button-item v-if="smsConfirm">
          <dx-button-options
            width="100%"
            type="default"
            template="createAccount"
            :use-submit-behavior="true"
          >
          </dx-button-options>
        </dx-button-item>
        <template #createAccount>
            <div>
              <span class="dx-button-text">
                  <dx-loadIndicator v-if="loading" width="24px" height="24px" :visible="true" />
                  <span v-if="!loading">{{$t('Auth.ConfirmSms')}}</span>
              </span>
            </div>
        </template>
    </dx-form>
    <div class="btn-sms">  
      <DxButton
        :disabled="resendAccess"
        type="default"
        :text="$t('Auth.SendSms')"
        width="100%"
        @click="sendSms()"
      />
    </div>
    <div class="login-link">
              {{$t('Auth.HaveAnAccount')}} <router-link to="/login">{{$t('Auth.SignIn')}}</router-link>
    </div>
  </form>
</template>

<script>
import DxForm, {
  DxItem,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
  DxRequiredRule
} from 'devextreme-vue/form';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import notify from 'devextreme/ui/notify';
import DxButton from 'devextreme-vue/button';

export default {
  components: {
    DxForm,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxRequiredRule,
    DxLoadIndicator,
    DxButton
  },
  data() {
    return {
        formData: {},
        loading: false,
        smsConfirm: false,
        resendAccess: false,
        langs: ['ru', 'kz']
    }
  },
  methods: {
    sendSms: async function() {
      const { phone} = this.formData;
        this.resendAccess = true;

        setTimeout(() => {this.resendAccess = false;}, 10000)

        this.$store.dispatch('auth/SmsSend', {
        phone: phone,
        isRegistering: this.isRegistering()
        })
        .then(() => {
            this.smsConfirm = true;
        })
        .catch(error => {
            notify(this.$i18n.t(error.message), "error", 2000);
        })
    },
    onSubmit: async function() {
        const { phone, smsCode } = this.formData;
        this.loading = true;

        this.$store.dispatch('auth/SmsConfirm', {
        phone: phone,
        smsCode: smsCode
      })
        .then(() => {
          if (this.$route.params.id == "register") {
            this.$router.push("/create-account");
          } else {
            this.$router.push("/reset-password");
          }
          
        })
        .catch(error => {
          this.loading = false;
          notify(this.$i18n.t(error.message), "error", 2000);
        })
    }
    ,
    confirmPassword(e) {
      return e.value === this.formData.password;
    },
    isRegistering() {
      if (this.$route.params.id == "resetpassword")
        return false;
      else 
        return true;
    }
  }
}
</script>

<style lang="scss">
@import "../../themes/generated/variables.base.scss";

.sms-cofirm-form {
  .policy-info {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    font-size: 14px;
    font-style: normal;

    a {
      color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    }
  }

  .login-link {
    margin-top: 10px;
    color: $base-accent;
    font-size: 16px;
    text-align: center;
  }
  
  .btn-sms{
    margin-top: 10px;
  }
}

</style>
